.ProductImageSlider .slick-slider.one {
  margin-bottom: 0.5rem;
}
.ProductImageSlider .slick-slider.one .slick-track .slick-slide img {
  width: 100%;
}
.ProductImageSlider .slick-slider.two .slick-track {
}
.ProductImageSlider .slick-slider.two .slick-track .slick-slide {
  cursor: pointer;
}
.ProductImageSlider .slick-slider.two .slick-track .slick-slide img {
  width: 100%;
}
.ProductImageSlider .slick-slider.two .slick-track .slick-slide > div {
  margin-right: 11px;
  padding: 0 2px 0;
}
.ProductImageSlider .slick-slider.two .slick-track .slick-slide.slick-current > div {
  border-bottom: 4px solid #dee2e6;
}
.ProductImageSlider .slick-slider .slick-arrow {
  display: none;
}