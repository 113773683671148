.Wellness {
  padding-bottom: 6rem;
}
.Wellness .center {
  text-align: center;
}
.Wellness h3 {
  margin-bottom: 2rem;
}
.Wellness .tmpmsg {
  font-size: 1.4rem;
}
.Wellness .tmpmsg.up {
  text-transform: uppercase;
}
.Wellness .vidcol {
  margin-bottom: 1rem;
  padding-right: 8px;
  padding-left: 8px;
  line-height: 20px;
  font-size: 15px;
}
.Wellness .vidcol .wrap {
  cursor: pointer;
}
.Wellness .vidcol .vid {
  min-height: 150px;
  width: 100%;
  background-color: #DDD;
  position: relative;
}
.Wellness .vidcol .vid .new {
  text-transform: uppercase;
  background-color: #ffffffd1;
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 2px;
}
.Wellness .vidcol .title {
  font-weight: 500;
}
.Wellness .vidcol .meta {
  font-size: 14px;
  color: #555;
}