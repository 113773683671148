.Connect {
  padding-bottom: 6rem;
}
.Connect .contactcontent h3 {
  text-align: center;
  margin-bottom: 2rem;
}
.Connect .contactcontent .talk {
  margin-top: 2rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
}
@media (max-width: 576px) {
  .Connect .contactcontent {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.Connect .ContactForm {
  margin-top: 3rem;
}
.Connect .ContactForm .has-error .valid-feedback {
  display: block;
}
.Connect .ContactForm .has-error .form-control {
  border-color: #dc3545;
  background-color: #ffe8e8;
}
.Connect .confirmation {
  margin-top: 2rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
}
.Connect .confirmation .message {
  font-weight: 500;
}
.Connect .confirmation .number {
  text-transform: none;
}