.Product.main {
  background-color: #fff;
  padding-bottom: 3rem;
}
.Product .vendor {

}
.Product .sku {
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
}
.Product .title {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
}
.Product .price {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.Product .variants {
  margin-bottom: 1rem;
}
.Product .variants .btn {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
.Product .qty {
  margin-bottom: 1rem;
}
.Product .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
}
.Product .tabs {
  margin-top: 3rem;
}
.Product .tabs .card,
.Product .tabs .accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.Product .tabs .card .card-body {
  padding: 1rem 0 0 0;
}
.Product .tabs .card .card-header {
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 1.1rem;
  border-bottom: 1px solid rgba(0,0,0,.125);
  cursor: pointer;
}
.Product .tabs .card .card-header .in {
  padding: 0.8rem 0 0.8rem 0;
}
.Product .tabs .card .card-header .updown {
  float: right;
}
.Product .stock,
.Product .quantity {
  float: left;
}
.Product .quantity {
  margin-right: 1rem;
}
.Product .stock .icon {
  margin-top: -4px;
}
@media (max-width: 767px) {
  .Product .carousel-wrap {
    margin-bottom: 2rem;
  }
}