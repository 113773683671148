.OrderConfirm {
  min-height: 600px;
  padding-bottom: 80px;
}
.OrderConfirm .center {
  text-align: center;
  margin-bottom: 5px;
}
.OrderConfirm .loading {
  margin-top: 40px;
  text-align: center;
  font-size: 50px;
}
.OrderConfirm .message {
  margin-top: 40px;
}
.OrderConfirm .message .thankyou {
  text-transform: none;
  color: #c1c1c1;
  font-weight: bold;
  letter-spacing: 5px;
  font-size: 2.4rem;
}