.Checkout {
  min-height: 600px;
  padding-bottom: 80px;
}
.Checkout .center {
  text-align: center;
  margin-bottom: 5px;
}
.Checkout .form-label {
  margin-bottom: 0;
  margin-top: .5rem;
  font-size: 14px;
  color: grey;
}
.Checkout .form-label.first {
  margin-top: 0;
}
.Checkout .shipping-methods {
  display: table;
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: .25rem;
}
.Checkout .center-vertical {
  display: table-cell; 
  vertical-align: middle; 
}
.Checkout .form-group {
  margin-bottom: 4px;
}
.Checkout .order-summary-title {
  float: left;
}
.Checkout .order-summary .stickywrap {
  position: sticky;
  top: 64px;
}
.Checkout .edit-cart {
  float: right;
}
.Checkout .edit-cart .btn {
  padding: 0;
  font-size: 14px;
  vertical-align: top;
}
.Checkout .product-list {
  list-style: none;
  padding: 0;
  margin-top: 12px;
  margin-bottom: 4px;
}
.Checkout .product-list li {
  margin-bottom: 10px;
}
.Checkout .product-list .preview {
  width: 100%;
  max-width: 80px;
  min-width: 50px;
  display: block;
  margin: 0 auto;
}
.Checkout .product-list .title {
  font-size: 13px;
  line-height: 17px;
}
.Checkout .product-list .price-x-qty {
  font-size: 14px;
  margin-top: 4px;
  color: grey;
}
.Checkout .product-list .line-total {
  text-align: right;
  font-size: 14px;
  line-height: 4rem;
}
.Checkout .subtotal,
.Checkout .shipping,
.Checkout .promoCode {
  margin-bottom: 4px;
}
.Checkout .total .amount {
  font-size: 18px;
  font-weight: 600;
}
.Checkout .show-more {
  text-align: center;
  cursor: pointer;
}
.Checkout .show-more button {
  font-size: 14px;
}
.Checkout .subtotal-division {
  margin-top: 0.2rem;
}
.Checkout .loading {
  margin: 40px auto 0 auto;
  font-size: 50px;
}
.UpdatingFees  .modal-content {
  background-color: #f3f4ef;
  border-radius: 10px;
  border: none;
}







