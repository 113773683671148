.About {
  padding-bottom: 6rem;
}
.About .center {
  text-align: center;
}
.About .larger {
  font-size: 18px
}.About .larger1 {
  font-size: 24px
}
.About .section {
  margin-bottom: 2rem;
}
.About .meetcuatro h3 {
  text-align: center;
  margin-bottom: 2rem;
}
.About .meetcuatro .subtitle {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
.About h3.fourthings {
  margin-bottom: 1rem;
}
.About .mission {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.About .meetcuatro h5 {
  margin-top: 1.2rem;
}
.About .missiondets .pillwrap {
  text-align: center;
}
.About .missiondets .numpill {
  background-color: #F3EAD9;
  border-radius: 18px;
  padding: 3px 25px;
  display: inline-block;
  min-width: 200px;
  margin-bottom: 30px;
}
.About .missiondets .numpill h5 {
  padding: 0;
  margin: 0;
  text-align: center;
}
.About .missiondets .numicon {
  margin-top: 40px;
  margin-bottom: 30px;
}
.About .missiondets .numicon img {
  max-height: 100px;
  margin: 0 auto;
  display: block;
}
.About .quote {
  text-align: center;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  /*padding-left: 1rem;*/
  padding-bottom: 0.3rem;
  /*border-left: 3px solid rgba(0,0,0,.25);*/
}
.About .quote img {
  max-width: 26px;
  margin-bottom: 1rem;
}
.About .quote h4 {
  margin-bottom: 0;
}
.About .quote .source {
  /*text-align: left;*/
  font-size: 15px;
  /*margin-left: 2rem;*/
  margin-top: 0.5rem;
}
.About .parklanding {
  height: 45vh;
  background-position: 20%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
}
.About .parklanding .iso {
  width: 90%;
  max-width: 300px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.About .parklanding .iso img {
  width: 100%;
}
.About .parklanding .coming {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-top: 1rem;
  letter-spacing: 5px;
}
.About .parklanding .coming h5 {
  font-size: 0.95rem;
}
.About .pano {
  padding-left: 0;
  padding-right: 0;
}
.About .showroom .asterisk {
  text-align: center;
}
.About .showroom .asterisk img {
  max-width: 40px;
  margin-bottom: 12px;
}
.About .showroom ul {
  padding-left: 3rem;
}
.About .showroom ul li {
  margin-bottom: 0.2rem;
  padding-left: 8px;
}
.About .elpaso {
  padding: 0;
}
.About .elpaso .lft {
  padding-right: 0.2rem;
}
.About .elpaso .rgt {
  padding-left: 0.2rem;
}
.About .actionplan {
  position: relative;
  margin: 0 auto;
  min-height: 600px;
}
.About .actionplan .cardTop {
  width: 100%;
  height: 160px;
  background: #F2EDDE;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
}
.About .actionplan .tlwrap {
  background: #F2EDDE;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.About .actionplan .tlwrap .in {
  background: #fff;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 110px;
  bottom: 10px;
}
.About .actionplan .timeline {
  left: 5%;
  right: 5%;
  /*background: #ECF1F524;*/
  /*mix-blend-mode: normal;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);*/
  overflow: hidden;
  position: absolute;
  top: 7px;
  bottom: 0;
  /*box-shadow: 0px 20px 53px -30px rgba(95, 102, 173, 0.566816);*/
  padding-left: 1.5rem;
  padding-top: 1rem;
}
.About .actionplan .timeline h3,
.About .actionplan .timeline h4 {
  text-align: center;
}
.About .actionplan .timeline h3 {

}
.About .actionplan .timeline h4 {
  font-size: 0.95rem;
  color: #a4a973;
}

.About .actionplan .timeline .box {
  width: 100%;
  margin-top: 153px;
  position: absolute;
  bottom: 10px;
  top: -50px;
}
.About .actionplan .timeline .box .contain {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.About .actionplan .timeline .box .contain .cars {
  color: #000;
  position: absolute;
  left: 26px;
  right: 1.2rem;
  top: 0;
  overflow-y: scroll;
  bottom: 0;
  padding-right: 15px;
  padding-bottom: 3rem;
}
.About .actionplan .timeline .box .contain .cars .car {
  position: relative;
  border-color: transparent;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 6px;
  background: #FFFFFF;
  box-shadow: 0 2px 2px 0 #eeeeee40;
  box-shadow: 0px 10px 13px -10px rgba(32, 29, 70, 0.11);
  margin-bottom: 10px;
}
.About .actionplan .timeline .box .contain .cars .car .status {
  position: absolute;
  right: 9px;
  top: 50%;
  font-size: 1.2rem;
  color: #AEB28A;
  line-height: 0;
  margin-top: -9px;
}
.About .actionplan .timeline .box .contain .cars .car.phase {
  box-shadow: none;
  padding: 4px 0;
  margin-bottom: 0.2rem;
  font-size: 12px;
  background-color: transparent;
  margin-top: 24px;
}

.About .actionplan .timeline .box .contain .cars .car.phase .name {
  /*background: #AEB28A;*/
  /*padding: 2px 8px;*/
  /*border-radius: 12px;*/
  /*color: #efe7dc;*/
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 0.95rem;
}

.About .actionplan .timeline .box .contain .cars .car .date {
  font-style: normal;
  font-size: 11px;
  line-height: 19px;
  margin-left: 25px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.About .actionplan .timeline .box .contain .cars .car p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: inherit;
  margin-left: 25px;
  padding-right: 36px;
}
.About .actionplan .timeline .box .contain .lines {
  margin-top: 0;
}
.About .actionplan .timeline .box .contain .lines .dot {
  width: 14px;
  height: 14px;
  background: #D1D6E6;
  border-radius: 7px;
}
.About .actionplan .timeline .box .contain .lines .line {
  height: 75px;
  width: 2px;
  background: #D1D6E6;
  margin-left: 5.3px;
}
.About .actionplan .timeline .box .contain .lines .line.first {
  height: 89px;
}
.About .actionplan .timeline .box .contain .lines .line.last {
  height: 21px;
}
.About .mapboxgl-ctrl-attrib-button {
  display: none;
}
@media (max-width: 576px) {
  .About .meetcuatro {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}