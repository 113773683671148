.Login {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
.Login h2 {
  margin-bottom: 1.8rem;
}
@media all and (min-width: 332px) {
  .Login form {
    margin: 0 auto;
    max-width: 332px;
  }
}
.Login form a {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}
.Login .createAccount {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 16px;
}