.App .appversion {
  
}
.App {
  background-color: #f3f4ef!important;
  padding-top: 50px;
}
.main {
  background-color: #fff;
  min-height: 30rem;
}
.bg-dark {
  background-color: #f3f4ef!important;
}
.App .navbar {
  background-color: #f3f4ef;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 11;
}

.App .navbar .menu,
.App .navbar .menu .row {
  width: 100%;
  height: 18px;
  margin: 0;
}
.App .navbar .menu {
  margin: 18px 0 0 0;
}
.App .navbar .menu .left {
  text-align: left;
}
.App .navbar .menu .left a {
  padding: 0;
}
.App .navbar .menu a {
  color: #000;
}
.App .navbar .menu .right {
  text-align: right;
}
/*.App .navbar .menu .right a {
  padding: 0;
}*/
.App .navbar .menu .group {
  padding: 0;
}
.App .navbar .menu .group .item.first {
  padding-left: 0;
  line-height: 0;
}
.App .navbar .menu .group .item.last {
  padding-right: 0;
  line-height: 0;
}
.App .navbar .menu h3.link {
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 0.95rem;
  margin-bottom: 0;
}
.App .navbar .navbar-text {
  padding-bottom: 14px;
  padding-top: 13px;
}
.App .navbar .menucompact {
  text-align: center;
  margin: 3px 0 0 0;
}
.App .navbar .menucompact ul {
  padding: 0;
  margin: 0;
}
.App .navbar .menucompact ul li {
  padding: 0;
  list-style-type: none;
}
.App .navbar .menucompact h3.link {
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 0.95rem;
  margin-bottom: 0;
}
.App .navbar-collapse.collapse,
.App .navbar-collapse.collapsing {
  background-color: #f3f4ef;
  margin-top: -2px;
  padding-bottom: 1rem;
}
.App .navbar .cart {
  /*margin-top: -5px;*/
}
.App .navbar .cart.nav-link {
  padding: 0 0.6rem 0 0;
  display: inline-block;
}
.App .navbar .cart .badge-light {
  background-color: transparent;
  font-size: 0.9rem;
}
.App .navbar .cart .shopping-bag {
  font-size: 20px;
}
.App .navbar .cart-total {
  color: #787F43;
  font-weight: 600;
}
.App .navbar .cart .badge {
  margin-left: 4px;
}
.App .navbar .account {
  margin-top: -5px;
}
.App .navbar .account .dropdown-toggle::after {
  display: none;
}
.App .navbar .account.nav-item {
  padding: 0 0.6rem 0 0;
  display: inline-block;
}
.App .navbar .account .dropdown-toggle {
  padding: 0;
}
.App .navbar .account .icon {
  font-size: 22px;
}
.App .navbar .account .dropdown-menu {
  line-height: initial;
  background-color: #fefffb;
  border: 1px solid #e7eade;
  border-radius: 0;
}
.App .navbar .account .dropdown-item.active,
.App .navbar .account .dropdown-item:active {
    color: initial;
    text-decoration: none;
    background-color: transparent;
}
.App .navbar .navbar-toggler {
  color: #000;
  border: none;
}
.App .navbar .carttotal {
  font-weight: 500;
  color: #212529;
}


.categories .col {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
.categories .col .cat-image {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.categories .col .cat-image img {
  max-width: 100%;
  max-height: 100%;
}
.categories .col {
  cursor: pointer;
}
.last-row {
  margin-bottom: 3em;
}
.popover-body hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.delivery-address {
  width: 100%;
  font-size: 14px;
  color: #787F43;
  text-align: left;
  display: inline-block;
  margin-left: 5px;
}
.delivery-address .marker {
  font-size: 18px;
  margin-top: -5px;
}
.delivery-address .value {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.delivery-address-change {
  font-size: 14px;
  cursor: pointer;
  color: #fff;
}
.delivery-address-change:hover {
  text-decoration: underline;
  color: #ead39d;
}
.App .center-logo img {
  display: block;
  margin: -3px auto 0 auto;
  max-height: 24px;
}
.App .center-logo-compact {
  position: absolute;
  left: 50%;
  margin-left: -58px;
  width: 116px;
}
.App .center-logo-compact a {
  padding: 0;
}
.App .center-logo-compact img {
  display: block;
  width: 100%;
}
.App .logo {
  width: 100%;
  max-width: 100px;
  margin: 22px;
}
.App .header {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 0;
  overflow: visible;
  z-index: 11;
  position: absolute;
  top: 0;
  width: 144px;
  left: 50%;
  margin-left: -72px;
}
.App .header .wrap {
  background-color: #efe7dc;
  display: inline-block;
  cursor: pointer;
}
.App .we-are-open {
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 0.3em 0;
}
.App .we-are-open h1 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  font-size: 28px;
  max-width: 200px;
  display: inline-block;
  line-height: 0;
}
.App .we-are-open .delivery-message {
  display: inline-block;
  font-size: 14px;
}
.App .we-are-open .local-pickup {
  display: block;
  font-size: 14px;
}
.App .navbar .collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}
.App .navbar .cat {
  cursor: pointer;
}
.App .navbar .menucompact .cat {
  cursor: pointer;
  padding: .5rem 1rem;
}
.App .navbar .cat .updown svg {
  margin-top: -5px;
}
.App .navbar .inset-submenu {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0.25rem 2px;
  /*box-shadow: rgb(213, 215, 205) 2px 2px 4px 0px inset, rgb(243, 244, 239) -2px -2px 4px 1px inset;*/
  background-color: #fefffb;
  border: 1px solid rgb(231, 234, 222);
  width: max-content;
}
.App .navbar .menu .inset-submenu {
  margin-top: 16px;
  padding: 1rem;
  border-top: none;
  float: left;
  width: max-content;
  min-width: 100%;
  text-align: left;
}
.App .navbar .inset-submenu .item a {
  padding: 0.5rem;
}
.App .navbar .menucompact .inset-submenu {
  width: 100%;
}
.App .free-shipping {
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background-color: #2b2b2b;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.App .free-shipping .qty {
  color: #a4a973;
}
@media (max-width: 767px) {
  .App .we-are-open img {
    display: block;
    margin: 0 auto;
    height: auto;
    max-height: 9vh;
    max-width: 200px;
  }
  .App .we-are-open .delivery-message {
    display: block;
  }
}
@media all and (max-width: 381px) {
  .delivery-address .value {
    font-size: 4vw;
    font-weight: bold;
    word-wrap: all;
  }
  .App .center-logo-compact {
    left: 47%;
  }
  .App .header {
    display: none;
  }
}