.BillingForm .email-header {
  margin-bottom: 0;
}

.BillingForm .payms {
  margin-top: 2rem;
}
.BillingForm .payms .title {
  padding-bottom: 0.5rem;
}
.BillingForm .paymrow {
  padding-left: 10px;
  padding-right: 10px;
}
.BillingForm .paymcol {
  padding-left: 5px;
  padding-right: 5px;
}

.BillingForm .paymcard {
  border: 1px solid #d5d5d5;
  padding: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  min-height: 90%;
}
.BillingForm .paymcard:hover {
  background-color: #a4a9732e;
}
.BillingForm .paymcard.active {
  background-color: #a4a973;
  color: #fff;
  border-color: rgb(52, 58, 64);;
}
.BillingForm .paymcard .last4 {
  font-size: 15px;
  font-weight: 500;
}
.BillingForm .paymcard .expires {
  font-size: 13px;
}
.BillingForm .paymcard .info .imgwrap {
  font-size: 2.5rem;
  line-height: 0;
  text-align: center;
}
.BillingForm .paymcard .editdel .sep {
  margin-left: 6px;
  margin-right: 6px;
}

.BillingForm .day-quick-picker .btn,
.BillingForm .day-half-picker .btn {
  cursor: pointer;
}
.BillingForm .day-abb {
  font-size: 11px;
}
.BillingForm .day-num {
  font-weight: 500;
}
.BillingForm .delivery-date {
  font-weight: 600;
  font-size: 20px;
  color: #dc3545;
  margin-top: 0.5em;
}
.BillingForm .card-field {
  background-color: white;
  padding: 9px 14px;
  border-radius: 0;
  border: 1px solid #CCC;
  /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);*/
  line-height: 1.3333333;
}
.BillingForm .card-field.StripeElement--focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  border-color: #66AFE9;
}
.BillingForm .has-error .form-control,
.BillingForm .has-error .card-field {
  border-color: #dc3545;
  background-color: #ffe8e8;
}
.BillingForm .valid-feedback {
  display: block;
}
.BillingForm .agree-notice {
  font-size: 13px;
  margin: 10px auto 0 auto;
  text-align: center;
}
.BillingForm .cc-icons {
  font-size: 25px;
  line-height: 0;
}
.BillingForm .cc-icons .cc {
  margin-right: 5px;
}
.BillingForm .shipping-opts .list-group-item {
  padding-left: 0.55rem;
  padding-right: 0.55rem;
  cursor: pointer;
}
.BillingForm .edit-address button,
.BillingForm .showAllShipOpts button {
  font-size: 14px;
}
.BillingForm .showAllShipOpts {
  text-align: center;
}
.BillingForm .service-active {
  float: left;
  margin-right: 0.55rem;
  color: #d6d6d6;
  font-size: 20px;
}
.BillingForm .shipping-opts .list-group-item.selected .service-active {
  color: #787F43;
}
.BillingForm .shipping-opts {

}
.BillingForm .service-left {
  float: left;
}
.BillingForm .service-left .name {
  font-size: 13px;
}
.BillingForm .service-left .delivery-days {
  color: gray;
  font-size: 12px;
}
.BillingForm .service-price {
  float: right;
  font-size: 13px;
}
.BillingForm .loading {
  font-size: 50px;
}
.BillingForm .promoCode {
  margin-bottom: 5px;
}
.BillingForm .promoCodeApply .btn,
.BillingForm .promoCodeRemove .btn {
  display: block;
  margin-top: 2px;
}
.BillingForm .newCard .saveCard {
  text-align: right;
}
@media (max-width: 576px) {
  .BillingForm .promoCodeApply .btn {
    margin-right: 0;
    margin-left: auto;
  }
}
@media (min-width:  767px) {
  .BillingForm {
    padding-left: 1.5rem;
  }
}
.DeliveryDayPicker .modal-body {
  text-align: center;
}
.DeliveryDayPicker .DayPicker-Day {
  cursor: pointer;
  border-radius: 10%;
}
.DeliveryDayPicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DeliveryDayPicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #dc3545;
}
.DeliveryDayPicker .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #fff0f0;
}