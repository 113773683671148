.ChangeName .narrow {
  max-width: 330px;
  margin: 0 auto;
  padding-top: 1rem;
}
.ChangeName form {
  margin-top: 2rem;
}
.ChangeName h3 {
  text-align: left;
}
