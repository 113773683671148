.Signup {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
.Signup h2 {
  margin-bottom: 1.8rem;
}
.Signup ul {
  list-style: none;
  padding-left: 10px;
  font-size: 14px;
}
.Signup .policy {
  padding-top: 0.8rem;
}
@media all and (min-width: 332px) {
  .Signup form {
    margin: 0 auto;
    max-width: 332px;
  }

  .Signup > .LoaderButton:first-child {
    margin-bottom: 15px;
  }
}