.Account.main {
  padding-bottom: 4rem;
}
.Account .profile h2 {
  margin-bottom: 0;
}
.Account .profile .pic {
  font-size: 4.5rem;
  color: grey;
  margin-bottom: 1rem;
  line-height: 0;
  text-align: center;
}
.Account .profile .email {
  font-size: 20px;
}
.Account .profile .emailverified {
  color: grey;
  font-size: 14px;
}
.Account .addcard {
  text-align: right;
}
.Account .payms {
  margin-top: 2rem;
}
.Account .payms .title {
  padding-bottom: 0.5rem;
}
.Account .paymcard {
  border: 1px solid #d5d5d5;
  padding: 1rem;
  margin-bottom: 15px;
}
.Account .paymcard .last4 {
  font-weight: 500;
}
.Account .paymcard .info .imgwrap {
  font-size: 2.5rem;
  line-height: 0;
  text-align: center;
  color: #555;
}
.Account .paymcard .editdel .sep {
  margin-left: 6px;
  margin-right: 6px;
}
.Account .orders {
  margin-top: 2rem;
}
.Account .orders .title {
  margin-bottom: 1rem;
}
.Account .order {
  margin-bottom: 1rem;
}
.Account .order .odets {
  margin-left: 0;
  margin-right: 0;
  background-color: #e8e8e8;
  padding: 0.4rem 0;
  font-size: 15px;
}
.Account .order .refdets {
  text-align: right;
}
.Account .order .sep {
  margin-right: 1rem;
  margin-left: 1rem;
}
.Account .order .prodlist {
  border: 1px solid #e8e8e8;
  padding: 1rem;
}
.Account .order .prodlist .prod .imgdeliver img {
  display: block;
  margin-left: auto;
  max-width: 90px;
}
.Account .order .prodlist .prod .status {
  font-weight: 500;
}
.Account .order .prodlist .prod .trackitem {
  text-align: right;
}
.Account .order .prodlist .prod .trackitem a:hover {
  color: #fff;
}



@media all and (min-width: 480px) {
  .Account > .LoaderButton:first-child {
    margin-bottom: 15px;
  }
}
@media all and (max-width: 575px) {
  .Account .name,
  .Account .email,
  .Account .verifyemail {
    text-align: center;
  }
  .Account .paymcard .editdel {
    text-align: right;
  }
  .Account .order .prodlist .prod .imgdeliver {
    margin-bottom: 1rem;
  }
}