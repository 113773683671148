.ChangePassword {
  padding-bottom: 5rem;
}
.ChangePassword .narrow {
  max-width: 330px;
  margin: 0 auto;
  padding-top: 1rem;
}
.ChangePassword form {
  margin-top: 2rem;
}
.ChangePassword h3 {
  text-align: left;
}
.ChangePassword ul {
  list-style: none;
  padding-left: 10px;
  font-size: 14px;
}
.ChangePassword .policy {
  padding-top: 0.8rem;
}
