.Terms {
  padding-bottom: 6rem;
}
.Terms h3 {
  text-align: center;
  margin-bottom: 2rem;
}
.Terms .updated {
  display: block;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-style: italic;
  text-align: center;
}
@media (max-width: 576px) {
  .Terms .all {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.Terms ol {
  display: block;
  font-weight: bold;
  padding-left: 1.2em;
}
.Terms p {
  padding-left: 1.2em;
}
.Terms p.intro {
  padding-left: 0;
}
.Terms ul {
  padding-left: 2rem;
}