.Cart {
  min-height: 600px;
  padding-bottom: 80px;
}
.Cart .main-2 {
  padding-top: 20px;
}
.Cart .shop-now {
  margin-top: 25px;
}
.Cart .preview {
  padding: 1em 0;
}
.Cart .preview img {
  max-width: 100%;
  max-height: 100px;
  margin: 0 auto;
  display: block;
}
.Cart .remove-item {
  cursor: pointer;
  font-size: 18px;
  color: grey;
  float: right;
}
.Cart .remove-item:hover {
  color: #000000;
}
.Cart {
  min-height: 600px;
}
.Cart .item:first-child {
  margin-top: 2em;
}
.Cart .item {
  padding-bottom: 1em;
  padding-top: 1em;
  border-bottom: 1px solid #dadada;
}
.Cart .item:last-child {
  margin-bottom: 2em;
}
.Cart .sku {
  color: grey;
  font-size: 14px;
  margin-bottom: 0.7em;
}
.Cart .title a {
  color: inherit;
}
.Cart .quantity {
  margin-bottom: 0.35em;
}
.Cart .each {
  margin-bottom: 0.35em;
}
.Cart .linetotal {
  margin-bottom: 0.35em;
  float: right;
}
.Cart .cart-summary {
  text-align: right;
  margin-top: 1em;
  margin-bottom: 25px;
  padding-bottom: 20px;
}
.Cart .subtotal {
  font-size: 20px;
  font-weight: 350;
  margin-bottom: 8px;
}
.Cart .subtotal .amount {
  font-weight: 600;
}
.Cart .tax {
  margin-bottom: 6px;
}
.Cart .tax,
.Cart .shipping {
  font-size: 14px;
  color: grey;
}
.Cart .tax .amount,
.Cart .shipping .amount {
  color: #212529;
}
.Cart .loading {
  margin-top: 40px;
  text-align: center;
  font-size: 50px;
}



.ShippingAddressInput p {
  text-align: center;
}
.ShippingAddressInput .add-location {
  text-align: center;
  margin-bottom: 10px;
  font-size: 40px;
  color: grey;
}
.ShippingAddressInput .or {
  text-align: center;
}
.ShippingAddressInput .buttons {
  text-align: center;
}
.ShippingAddressInput .buttons .btn {
  margin-top: 10px;
}
.ShippingAddressInput .local-pickup {
  margin-top: 1rem;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.ShippingAddressInput .modal-content {
  background-color: #f3f4ef;
  border-radius: 10px;
  border: none;
}
@media all and (min-width: 244px) {
  .ShippingAddressInput .next-modal {
    margin-left: 5px;
  }
  .ShippingAddressInput .close-modal {
    margin-right: 5px;
  }
}
/* Google place search bar */
.pac-container {
  z-index: 9000;
}









