.AddCard .narrow {
  max-width: 330px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 5rem;
}
.AddCard form {
  margin-top: 2rem;
}
.AddCard h3 {
  text-align: left;
}