.Cuatro.main {
  background-color: #fff;
  overflow: hidden;
}
.Cuatro .form-group {
  margin-bottom: 0;
}
.
@media (max-width: 767px) {
  .Cuatro .topads {
    padding-top: 1.5em;
  }
}

.Cuatro .link {
  color: #09d3ac;
}

.Cuatro .product-categories .product {
  padding: 20px;
  cursor: pointer;
}
.Cuatro .product-categories .desc {
  margin-top: 10px;
  text-align: center;
}
.Cuatro .product-categories .preview img {
  max-width: 100%;
}
.Cuatro .product-categories .product:hover .preview img {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.Cuatro .product-categories-mobile {
  margin-top: 1rem;
}
.Cuatro .product-categories-mobile a {
  color: inherit;
}
.Cuatro .product-categories-mobile .product {
  padding-bottom: 1.8em;
  margin-bottom: 0;
  cursor: pointer;
}
.Cuatro .product-categories-mobile .desc {
  margin-top: 0;
  text-align: left;
}
.Cuatro .product-categories-mobile .preview-wrap {
  padding-right: 0;
}
.Cuatro .product-categories-mobile .preview img {
  max-width: 100%;
}
.Cuatro .product-categories-mobile .cat {
  margin: 1.8em 0;
}
.Cuatro .product-categories-mobile .cat .title {
  margin-right: 10px;
  font-size: 22px;
}
.Cuatro .product-categories a {
  color: inherit;
}
.Cuatro .product-categories .cat {
  margin: 25px 0 5px 0;
}
.Cuatro .product-categories .cat .title {
  font-size: 25px;
  margin-right: 10px;
}
.Cuatro .product-categories .cat .titleEN {
  font-size: 25px;
  font-style: italic;
}
@media all and (max-width: 991px) {
  .Cuatro .product-categories .products .product {
    padding: 10px;
    cursor: pointer;
  }
  .Cuatro .product-categories .products .product .price-tag {
    top: 15px;
    right: 10px;
  }
}
.Cuatro .product-categories .title {
  display: block;
  margin-bottom: 0.4rem;
}
.Cuatro .product-categories .price-tag {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.Cuatro .product-categories .price-tag .from {
  font-size: 12px;
  font-weight: 500;
}
.Cuatro .product-categories .price-tag .old {
  text-decoration: line-through;
  color: #de7a84;
}
.Cuatro .product-categories-mobile .desc-col {
  align-items: center;
  display: flex;
}
.Cuatro .product-categories-mobile .title {
  font-size: 17px;
  margin-right: 5px;
  line-height: 20px;
}
.Cuatro .product-categories-mobile .price-tag {
  margin-top: 0.7em;
}
.Cuatro .product-categories-mobile .price-tag .price {
  font-size: 16px;
  font-weight: 600;
}
.Cuatro .product-categories-mobile .price-tag .from {
  font-weight: 500;
  font-size: 12px;
  margin-right: 5px;
}
.Cuatro .product-categories-mobile .price-tag.old {
  text-decoration: line-through;
  color: grey;
}
.Cuatro .product-categories-mobile .sku-stock {
  font-size: 12px;
  margin-top: 0.5em;
  color: gray;
}
.Cuatro .product-categories .sku-stock {
  font-size: 12px;
  margin-top: 0.1em;
  color: gray;
}


.Cuatro .collection-header {
  text-align: center;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
}
.Cuatro .collection-desc {
  text-align: center;
  max-width: 500px;
  margin: 0 auto 2rem auto;
  font-size: 15px;
}
.Cuatro .head-tools {
  padding-left: 10px;
  padding-right: 10px;
}
.Cuatro .head-tools .dropdown {
  text-align: right;
  margin-right: 10px;
}
.Cuatro .head-tools .dropdown .dropdown-menu {
  z-index: 2;
}


.Cuatro .loadingPs {
  padding-top: 3rem;
  min-height: 15rem;
  text-align: center;
}
.Cuatro .loadingPs .progress-bar {
  background-color: #a8a8a8 !important;
}
.Cuatro .loadingPs .progress {
  height: 0.35rem;
  max-width: 6rem;
  margin: 0 auto;
}
.Cuatro .loadingPs .loading {
  margin-bottom: 1.6rem;
}
.Cuatro .loadingPs .loading .spin {
  font-size: 50px;
}

@media all and (max-width: 991px) {
  .Cuatro .head-tools .dropdown {
    margin-right: 0;
  }
  .Cuatro .product-categories {
    margin-top: 0.5rem;
  }
}
@media (max-width: 576px) {
/*  .Cuatro .head-tools .dropdown {
    text-align: center;
  }*/
}
.dropdown.show .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.dropdown .btn-outline-dark:hover {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

