.BuyTheLookModal {
  margin: auto !important;
  padding: 20px;
}
.BuyTheLookModal .modal-content {
  height: 100%;
  background-color: transparent;
}
.BuyTheLookModal .modal-body {
  background-color: #fff;
  height: 100%;
}
.BuyTheLookModal .mainimg {
  max-width: 100%;
  max-height: calc(100vh - 50px);
  display: block;
  margin: 0 auto;
}
.BuyTheLookModal .navtool .btn {
  background-color: transparent;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  margin: 0;
  padding: 0;
}
.BuyTheLookModal .navtool .btn.disabled {
  opacity: 0.25;
}
.BuyTheLookModal .navtool .btn.next {
  margin-left: 2rem;
}
.BuyTheLookModal .navtool .btn.close {

}
.BuyTheLookModal .navtool .btn:focus {
  border: 0;
}
.BuyTheLookModal .navtool .btn:not(:disabled):not(.disabled):focus, 
.BuyTheLookModal .navtool .btn:not(:disabled):not(.disabled):hover {
  opacity: .75;
}

.BuyTheLookModal .btn-primary:not(:disabled):not(.disabled).active, 
.BuyTheLookModal .btn-primary:not(:disabled):not(.disabled):active, 
.BuyTheLookModal .show > .btn-primary.dropdown-toggle {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.BuyTheLookModal .btn-primary.focus, 
.BuyTheLookModal .btn-primary:focus {
  border: none;
  background-color: transparent;
  box-shadow: none;
}



.BuyTheLookModal .info .navtool {
  padding: 15px 15px;
}
.BuyTheLookModal .prods {
  margin-top: 0.6rem;
}
.BuyTheLookModal .prods .h-link {
  padding-bottom: 2.4rem;
}
.BuyTheLookModal .prods .name {
  text-align: center;
  font-size: 14px;
  margin-top: 0.5rem;
}
.BuyTheLookModal .prods a {
  color: #212529;
}
.BuyTheLookModal .prods img {
  width: 100%;
}
.BuyTheLookModal .prods .row.sub {
  height: 100%;
}
.BuyTheLookModal .prods .row.l-1 {
  margin-bottom: 1.8rem;
}
.BuyTheLookModal .prods .addtobag {
  text-align: center;
  margin-top: 0.5rem;
}
.BuyTheLookModal .prods .price {
  font-weight: 600;
}
.BuyTheLookModal .prods .addtobag .btn {
  font-size: 9px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: #000;
  color: #fff;
  border: none;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 0.15rem 0.65rem;
}
.BuyTheLookModal .copy {
  text-align: center;
  padding: 0.5rem;
}
.BuyTheLookModal .separator {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
.BuyTheLookModal .separator img {
  max-width: 70px;
}
.BuyTheLookModal .meta {
  margin-top: 0.7rem;
  color:  #666;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.BuyTheLookModal .meta a {
  display: block;
  padding-bottom: 2rem;
  color:  #666;
  font-size: 13px;
}
@media (max-width: 576px) {
  .BuyTheLookModal {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .BuyTheLookModal .modal-body {
    padding-top: 0;
  }
  .BuyTheLookModal .prods img {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 160px;
  }
  .BuyTheLookModal .mediainfowrap {
    margin-right: -16px;
    margin-left: -16px;
  }
  .BuyTheLookModal .media {
    padding: 0;
  }
}
@media (max-width:  1199px) {
  .BuyTheLookModal {
    max-width: 100%;
  }
}
@media (min-width:  767px) {
  .BuyTheLookModalModal {
    bottom: 0;
    right: 0;
  }
  .BuyTheLookModal {
    height: 100%;
  }
  .BuyTheLookModal .mediainfowrap {
    height: 100%;
  }
  .BuyTheLookModal .info {
    height: 100%;
    padding: 0;
  }
  .BuyTheLookModal .info .navtool {
    padding: 0 15px;
  }
  .BuyTheLookModal .scrollwrap {
    position: absolute;
    top: 40px;
    bottom: 0;
    right: 0;
    left:  0;
    overflow-y: scroll;
    padding: 0 15px;
  }
  .BuyTheLookModal .media {
    height: 100%;
    overflow: hidden;
  }
}