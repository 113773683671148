.Shipping {
  padding-bottom: 6rem;
}
.Shipping h3 {
  text-align: center;
  margin-bottom: 2rem;
}
.Shipping .updated {
  display: block;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-style: italic;
  text-align: center;
}
@media (max-width: 576px) {
  .Shipping .all {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.Shipping ol {
  display: block;
  font-weight: bold;
  padding-left: 1.2em;
}
.Shipping p {
  padding-left: 1.2em;
}
.Shipping p.intro {
  padding-left: 0;
}
.Shipping ul {
  padding-left: 2rem;
}