.Footer {
  font-size: 14px!important;
  background-color: #f3f4ef;
  padding-bottom: 3rem;
}
.Footer a {
  color: inherit;
}
.Footer .head {
  font-size: 1rem;
  font-weight: 600;
}
.Footer .cuatro {
  padding-top: 2em!important;
}
.Footer .left {
  text-align: left;
}
.Footer .right {
  text-align: right;
}
.Footer .list-vertical {
  padding: 0;
  margin-bottom: 1.4rem;
}
.Footer .list-vert-item {
  list-style: none;
  margin: 0 0 0.4rem 0;
  padding: 0;
}
.Footer .list-inline-item.left {
  text-align: center;
}
.Footer .center {
  text-align: center;
}
.Footer .bottom {
  text-transform: uppercase;
  margin-top: 1rem;
  font-weight: 500;
}
.Footer .bottom .legal {
  text-transform: none;
  margin-top: 1rem;
  font-weight: 500;
}
.Footer .logo {
  max-width: 80px;
  margin:  0;
}
.Footer .logo img {
  width: 100%;
}
@media all and (max-width: 576px) {
  .Footer .right {
    text-align: center;
  }
  .Footer .left {
    text-align: center;
  }
  .Footer .list-inline-item.left {
    text-align: center;
  }
}
.Footer .list-inline-item.social {
  margin-right: 0;
}
.Footer .social-icons {
  min-width: 152px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.Footer .social-icons .si {
  padding: 0 4px;
}
.Footer .social-icons .si img {
  max-width: 30px;
}
.Footer .contact-info {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Footer .policies li {
  letter-spacing:  2px;
}
.Footer .policies .list-inline-item:not(:last-child) {
  margin-right: 1.4rem;
}