.CardForm {
  min-height: 450px;
}
.CardForm .loading .spin {
  top: 50%;
  left: 50%;
  position: absolute;
  font-size: 50px;
  margin-top: -25px;
  margin-left: -25px;
}
.CardForm .card-field {
  margin-bottom: 1rem;
}
.CardForm .cardHsN {
  margin-bottom: 0.8rem;
}