body {
  margin: 0;
  font-family: 'Jost';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Jost';
}
html, body, #root {
  background-color: #f3f4ef!important;
}
h3 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;
}
h3.sub {
  font-size: 1.5rem;
}
h2 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;
  font-size: 1.7rem;
}
h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;
}
a {
  color: #000;
}
a:hover {
  color: #4d4d4d;
}
span.subtitle {
  display: block;
  color: #a4a973;
  font-weight: 700;
  font-size: 1.55rem;
}
.emphasis {
  font-weight: 600;
}
hr.short {
  width: 3rem;
  border-top: 3px solid rgba(0,0,0,.25);
}
hr.separator {
  width: 3rem;
  border-top: 2px solid rgba(0,0,0,.10);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
a:hover {
  text-decoration: none;
}
.dropdown-item:hover {
  background: #f2f2f2;
}
.btn,
.input-group>.input-group-append>.btn,
.input-group>.input-group-prepend>.btn  {
  border-radius: 0;
}
.input-group>.input-group-append>.btn {
  border-left: none;
}
.input-group>.input-group-prepend>.btn {
  border-right: none;
}
.form-control,
.form-control-sm,
.list-group-item:first-child,
.list-group-item:last-child,
.modal-content {
  border-radius: 0;
}
.btn.cuatro-primary {
  background-color: #a4a973;
  color: rgb(52, 58, 64);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  cursor: pointer;
}
.btn.cuatro-primary:hover {
  color: #fff;
}
.btn.cuatro-primary:disabled:hover {
  color: rgb(52, 58, 64);
  cursor: default;
}
.btn.cuatro-back {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 14px;
}
.btn.cuatro-back:hover {
  text-decoration: none;
}
.btn.cuatro-alert {
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  color: red;
  border: none;
}
.btn.cuatro-alert:hover {
  background-color: transparent;
  color: black;
}
.btn-link {
  color: #a4a973;
  transition: unset;
  padding: 0;
}
.btn-link:hover {
  color: #a4a973;
  text-decoration: underline;
}
.inlineicon {
}
.inlineicon.large {
  font-size: 1rem;
  margin-right: 0.4rem;
}

.spin {
  color: #a8a8a8;
  animation: spin 1.5s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}


form .feedback {
  width: 100%;
  min-height: 22px;
  font-size: 90%;
}
form .feedback.error {
  color: #28a745;
}
form .form-group {
  margin-bottom: 0.2rem;
}

.lds-grid {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(155, 155, 155, 0.3);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

