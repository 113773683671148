.Breadcrums {
  text-transform: uppercase;
  padding: 1.4rem 0 1.4rem 0;
  font-size: 12px;
  letter-spacing:  2px;
}
.Breadcrums .crum {
  display: inline-block;
  margin: 0 12px;
}
.Breadcrums .crum.first {
  margin-left: 0;
}
.Breadcrums a.crum {
  color: inherit;
  text-decoration: none;
}