.AddedToCart p {
  text-align: center;
  font-size: 22px;
}
.AddedToCart .check {
  text-align: center;
  margin-bottom: 10px;
  font-size: 36px;
  color: grey;
}
.AddedToCart .buttons {
  text-align: center;
}
.AddedToCart .buttons .btn {
  margin-top: 10px;
}
.AddedToCart .buttons .col-sm-6 {
  display: grid;
  align-content: center;
}
.AddedToCart .modal-content {
  background-color: #f3f4ef;
  border-radius: 10px;
  border: none;
}
@media all and (min-width: 409px) {
  .AddedToCart .to-cart {
    margin-left: 5px;
  }
  .AddedToCart .continue-shop {
    margin-right: 5px;
  }
}

