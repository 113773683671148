.WellnessShow {
  padding-bottom: 6rem;
}
.WellnessShow .vid {
	text-align: center;
	background-color: #f2edde;
	padding-top: 0.5rem;
	margin-bottom: 2.4rem;
}
.WellnessShow .vid .player {
	display: inline-block;
}
.WellnessShow .vid .player video {
	margin-bottom: -6px;
}
.WellnessShow .name .title {
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
}
.WellnessShow .meta {
	color: #555;
	font-size: 14px;
}

.WellnessShow .sectitle.video {
	margin-top: 2rem;
	margin-bottom: 0;
}
.WellnessShow .sectitle.tools {
	margin-top: 1rem;
	margin-bottom: 0;
}
.WellnessShow .sectitle .tab {
	padding: 12px;
	cursor: pointer;
	margin: 0 auto;
}
.WellnessShow .sectitle .tab:hover {
	background-color: #f7f7f7;
}
.WellnessShow .sectitle .tab.active {
	background-color: #f2edde;
}
.WellnessShow .sectitle .tab .icon {
	font-size: 38px;
	color: #a4a973;
}
.WellnessShow .sectitle .meter {
	text-align: center;
}
.WellnessShow .sectitle .meter.left {
	padding-right: 0;
}
.WellnessShow .sectitle .meter.right {
	padding-left: 0;
}
.WellnessShow .sectitle .subtitle {
	display: inline-block;
}
.WellnessShow .ingredsReadyProg {
	width: 50px;
	margin: 0 auto;
}
.WellnessShow .ingredsReadyProg .CircularProgressbar-path {
  stroke: #a4a973;
}
.WellnessShow .ingredsReadyProg .CircularProgressbar-trail {
  stroke: #ececec;
}
.WellnessShow .tab.active .ingredsReadyProg .CircularProgressbar-trail {
  stroke: #fff;
}
.WellnessShow .ingredsReadyProg .CircularProgressbar-text {
  fill: inherit;
  font-size: 40px;
  font-weight: 700;
  fill: #a4a973;
}
.WellnessShow .ingredsReadyProg .CircularProgressbar-background {
  
}
.WellnessShow .toolsReadyProg {
	width: 50px;
	margin: 0 auto;
}
.WellnessShow .toolsReadyProg .CircularProgressbar-path {
  stroke: #a4a973;
}
.WellnessShow .toolsReadyProg .CircularProgressbar-trail {
  stroke: #ececec;
}
.WellnessShow .tab.active .toolsReadyProg .CircularProgressbar-trail {
  stroke: #fff;
}
.WellnessShow .toolsReadyProg .CircularProgressbar-text {
  fill: inherit;
  font-size: 40px;
  font-weight: 700;
  fill: #a4a973;
}
.WellnessShow .toolsReadyProg .CircularProgressbar-background {
  
}

.WellnessShow .cardd-container-wrap {
	/*background-color: #100e17;*/
}
.WellnessShow .cardd-container {
	width: 100%;
	overflow-x: scroll;
	display: flex;
	background-color: #f2edde;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.WellnessShow .cardd {
	min-width: 230px;
	display: flex;
	flex-direction: column;
	padding: 1rem 0.5rem;
	transition: 0.2s;
	cursor: pointer;
}
.WellnessShow .cardd .inner {
	padding: 0.5rem;
	background-color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.WellnessShow .cardd .top {
	position: relative;
}
.WellnessShow .cardd .top .count {
	color: #8a8a8a;
}
.WellnessShow .cardd .top .ready {
	position: absolute;
	font-size: 20px;
	top: 0;
	right: 0;
	line-height: 0;
	color: #8a8a8a;
}
.WellnessShow .cardd:hover .top .ready {
	color: #a4a973;
}
.WellnessShow .cardd .top .ready.check {
	color: #a4a973;
}
.WellnessShow .cardd .top {
	font-size: 14px;
	color: #fff;
	margin-bottom: 6px;
}
.WellnessShow .cardd .name {
	font-weight: 600;
	font-size: 19px;
	letter-spacing: 3px;
	vertical-align: middle;
	margin-bottom: 0;
}
.WellnessShow .cardd .qty {
	display: inline-block;
	font-size: 35px;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: auto;
}
.WellnessShow .cardd .qtyunit {
	margin-bottom: 10px;
}
.WellnessShow .cardd .qty .frac .symb {
	font-size: 30px;
}
.WellnessShow .cardd .qty .combo {
	
}
.WellnessShow .cardd .qty .combo .whole {
	display: inline-block;
}
.WellnessShow .cardd .qty .combo .frac {
	display: inline-block;
}
.WellnessShow .cardd .qty .frac sup,
.WellnessShow .cardd .qty .frac sub {
	font-size: 20px;
}
.WellnessShow .cardd .qty .frac sup {
	margin-right: -3px;
}
.WellnessShow .cardd .qty .frac sub {
	margin-left: -3px;
}
.WellnessShow .cardd .unit {
	display: inline-block;
	font-size: 20px;
	font-weight: 400;
	margin-left: 10px;
}

.WellnessShow .cardd .item {
	margin-bottom: 0;
	margin-top: auto;
}
.WellnessShow .cardd .preview-wrap .preview img {
	width: 100%;
}

.WellnessShow .clipgridwrap {
	background-color: #f2edde;
	padding: 0.5rem;
	margin-bottom: 2.4rem;
}
.WellnessShow .clipgrid .inner {
	height: 100%;
	padding-bottom: 8px;
}
.WellnessShow .cliplink {
	padding: 4px;
	background-color: #fff;
	height: 100%;
}
.WellnessShow .col.thumb {
	padding: 0 4px;
}
.WellnessShow .cliplink .text {
	text-align: center;
	font-size: 13px;
	font-weight: 500;
	margin-top: 4px;
	line-height: 14px;
}
.WellnessShow .cliplink .preview {
	cursor: pointer;
}
.WellnessShow .cliplink .preview img {
	width: 100%;
}

.WellnessShow .fsvidplay {
	position: fixed;
	z-index: 11;
	width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
}
.WellnessShow .fsvidplay .player {
	position: absolute;
	top: 0;
	z-index: 11;
}
.WellnessShow .fsvidplay .scrim {
	position: absolute;
	z-index: 12;
	width: 100%;
	height: 100%;
	display: -moz-flexbox;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-box;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.WellnessShow .fsvidplay .topclose {
	color: #fff;
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 40px;
	line-height: 30px;
	cursor: pointer;
}
.WellnessShow .fsvidplay .controls {
	direction: ltr;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	width: 264px;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-moz-justify-content: space-between;
	-webkit-justify-content: space-between;
	-webkit-box-pack: justify;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	color: #fff;
	font-size: 40px;
	line-height: 30px;
	cursor: pointer;
}
.WellnessShow .fsvidplay .controls .play {
	min-width: 64px;
}

@media (max-width: 576px) {
  .WellnessShow .sectitle .right,
  .WellnessShow .sectitle .left {
    text-align: center;
  }
}














