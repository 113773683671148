.linkbutton {
  border: none;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1rem;
}

.linkbutton.dark {
  background-color: #000;
  color: #fff;
}
.linkbutton.dark:hover {
  color: #a4a973;
}
.linkbutton.light {
  background-color: #efe7dc;
}
.linkbutton.light:hover {
  color: #a4a973;
}
.linkbutton.white {
  background-color: #fff;
}
.linkbutton.white:hover {
  color: #a4a973;
}

.Home {
  padding-bottom: 0.5rem;
}
.Home h2.light {
  color: #efe7dc;
}
.Home h2.white {
  color: #fff;
}
.Home .text.light {
  font-weight: 450;
  color: white;
}
.Home .form-group {
  margin-bottom: 0;
}
.Home .home-links {
  text-align: center;
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.Home .home-links a {
  color: #000;
  display: block;
  width: 100%;
}
.Home .home-links .h-link {

}
.Home .home-links .h-link span {

}
.Home .home-links .h-link  img {
  padding-bottom: 1rem;
  width: 100%;
  max-width: 230px;
  display: block;
  margin: 0 auto;
}
.Home .home-links .r-link  img {
  padding-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
}
.Home .section-single {
  background-color: #d9b595;
}
.Home .section-single .row {
  height: 100%;
}
.Home .section-single .imagetop {
  max-width: 325px;
}
.Home .section-single .imagetop.one {
  margin-right: 0;
  margin-left: auto;
}
.Home .section-single .imagetop.two {
  margin-left: 0;
  margin-right: auto;
  max-width: 310px;
}
.Home .section-single .imagetop img {
  max-width: 100%;
}
.Home .section-single .middle {
  position: relative;
  height: 400px;
}
.Home .section-single.hero {
  height: 780px;
  background-color: #000;
}
.Home .section-single .lowerleft .content {
  top: 70%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: left !important;
  z-index: 1;
  max-width: 1240px;
}
.Home .section-single .lowerleft .content h2 {
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 28px;
  text-transform: none;
  max-width: 375px;
  margin-right: auto;
}
.Home .section-single .lowerleft .content h2 span {
  background-color: #EEE3D8;
  padding: 1px 16px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 48px;
}
.Home .section-single .lowerleft .content .text {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 1.3rem;
  max-width: 300px;
  margin-right: auto;
}





.Home .section-features {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.Home .section-features .feat img {
  display: block;
  margin: 0 auto;
  height: 6rem;
}
.Home .section-features .feat p {
  display: block;
  margin: 1rem auto 0 auto;
  text-align: center;
  max-width: 200px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 0.9rem;
  font-weight: 500;
}
.hgradcover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent);
}

.Home .section-single .imageback {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}
.Home .section-single .center.col {
  padding-left: 0;
  padding-right: 0;
}
.Home .section-single .center .content {
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  max-width: 1240px;
}
.Home .section-single .center .content h2 {
  font-weight: bold;
  text-transform: none;
  letter-spacing: 5px;
  font-size: 2.4rem;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.Home .section-single .center .content .text {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 1.3rem;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}



.Home .section-dual.container {
  max-width: 100%;
}
.Home .section-dual .left {
  height: 520px;
  display: flex;
  justify-content: center;
  background-color: #b8a896;
}
.Home .section-dual .right {
  text-align: center;
  background-color: #a4a973;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  overflow: hidden;
}
.Home .section-dual .right .wrap {

}
.Home .section-dual .right .sideimg {
  position: absolute;
  max-width: 140px;
  right: 10%;
  bottom: 2rem;
  z-index: 0;
}
.Home .section-dual .right .sideimg img {
  width: 80%;
}
.Home .section-dual .left {
  padding-right: 0;
  padding-left: 0;
}
.Home .section-dual .left .wrap {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Home .section-dual .left .wrap img {
  
}
.Home .section-dual h2 {
  font-weight: bold;
  text-transform: none;
  letter-spacing: 5px;
  font-size: 2.4rem;
  max-width: 400px;
}
.Home .section-dual .text {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 1.3rem;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.Home .separator img {
  max-width: 100px;
  margin-bottom: 0.9rem;
}

.Home .subland {
  background-color: #dfebdf;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.Home .subland .text {
  letter-spacing: 2px;
  max-width: 450px;
  margin: 0 auto 0 auto;
}

.Home .best-sellers .h-link {
  padding-bottom: 2.4rem;
}
.Home .best-sellers .name {
  text-align: center;
  font-size: 1.1rem;
  font-size: 1rem;
  line-height: 1.4rem;
  margin-top: 1rem;
}
.Home .best-sellers a {
  color: #212529;
}
.Home .best-sellers img {
  width: 100%;
}
.Home .best-sellers .row.sub {
  height: 100%;
}
.Home .best-sellers .addtobag {
  text-align: center;
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
  left: 0; 
  right: 0; 
}
.Home .best-sellers .price {
  font-weight: 600;
  text-align: center;
  margin-top: 0.4rem;
}
.Home .best-sellers .addtobag .btn {
  font-size: 11px;
  width: auto;
  margin-left: auto; 
  margin-right: auto;
  padding: 0.15rem 0.8rem;
}

.Home .shoprange {
  margin-top: 2rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Home .shopall {
  text-align: center;
  padding-bottom: 5rem;
}

.Home .side2side-wrap {
  position: relative;
  background-color: #fff;
  height: 50vh;
  min-height: 474px;
  width: 100%;
  overflow: hidden;
}
.Home .side2side-wrap figure {
  background-color: #fafafa;
}
.Home .side2side-wrap .back {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  background-color: #9b9281;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 100%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.Home .side2side-wrap .main-message {
  text-align: center;
  z-index: 3;
  position: relative;
  color: #fff;
  margin-top: 25vh;
}
.Home .carousel-title {
  text-align: center;
  margin-bottom: 2.1rem;
}
.Home .carousel-title .desc {
  font-size: 1.6rem;
}
.Home .carousel-title .handle {
  font-size: 1.4rem;
  font-weight: 600;
}
.Home .sw-container {
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  -ms-overflow-style: none;
}
.Home .sw-wrapper {
  box-sizing: content-box;
  scroll-behavior: smooth;
  height: auto;
  max-width: 100%;
  margin: 0;
  scroll-snap-type: x mandatory;
  -webkit-scroll-snap-type: mandatory;
  -webkit-scroll-snap-type: x mandatory;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-points-x: repeat(100%);
}
.Home .sw-wrapper .prev,
.Home .sw-wrapper .next {
  cursor: pointer;
  min-width: 60px;
  transition: opacity 0.25s;
  -webkit-transition: opacity 0.25s;
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Home .sw-wrapper .prev:hover,
.Home .sw-wrapper .next:hover {
  transition: opacity 0.25s;
  -webkit-transition: opacity 0.25s;
  opacity: 1;
}
.Home .sw-wrapper .prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.85);
}
.Home .sw-wrapper .next {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.85);
}
.Home .sw-wrapper .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  margin-left: -15px;
  margin-top: -15px;
}
.Home .sw-slide {
  margin-right: 3px;
  padding-top: 180px;
  width: 180px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.Home .sw-slide .board {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.25s;
  opacity: 0;
}
.Home .sw-slide .board .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: visible;
  width: 0;
  height: 0;
  line-height: 0;
  margin-left: -10px;
  margin-top: -10px;
}
.Home .sw-slide .board .icon svg {
  color: #fff;
  width: 20px;
  height: 20px;
}
.Home .sw-slide:hover .board {
  display: block;
  opacity: 1;
}


@media (max-width: 1200px) {
  .Home .side2side-wrap {
    height: 39vh;
    min-height: 260px;
  }
  .Home .side2side-wrap .main-message {
    margin-top: 14vh;
  }
}
@media (min-width: 1200px) {
  .Home .section-features {
    padding-left: 15rem;
    padding-right: 15rem;
  }
}
@media (max-width: 992px) {
  .Home .section-dual .right {
    align-items: inherit;
    padding-top: 7rem;
  }
  .Home .section-dual .right .sideimg {
    display: none;
  }
  .Home .section-dual .left {
    height: 620px;
  }
  .Home .section-dual .right .sideimg {
    right: auto;
  }
}
@media (max-width: 768px) {
  .Home .section-single.hero {
    height: 550px;
  }
  .Home .section-single .lowerleft .content {
    text-align: center !important;
    max-width: 1240px;
  }
  .Home .section-single .lowerleft .content h2 {
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;
  }
  .Home .section-single .lowerleft .content .text {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
  .Home .section-dual .right {
    padding: 5.8rem 3.0rem;
    align-items: inherit;
  }
  .Home .side2side-wrap .main-message {
    margin-top: 10vh;
  }
  .Home .side2side-wrap .back {
    background-position: 50% 50%;
  }
  .Home .home-links .h-link  img {
    max-width: 130px;
  }
  .Home .carousel-wrap .slick-slide img {
    height: 180px !important;
  }
}
@media (max-width: 576px) {
  .Home .section-features {
    padding-bottom: 0;
    padding-top: 3rem;
  }
  .Home .section-features .feat {
    padding-bottom: 3rem;
  }
  .Home .section-features .feat img {
    height: 5rem;
  }
/*  .Home .section-features .feat p {
    display: none;
  }*/
  .Home .section-dual .left {
    height: 450px;
  }
  .Home .side2side-wrap .main-message {
    margin-top: 11vh;
  }
  .Home .section-dual .right .sideimg {
    display: block;
  }
  .Home .side2side-wrap .main-message h1 {
    font-size: 2rem;
  }
  .Home .side2side-wrap .back {

  }
  .Home .section-dual .right {
    padding: 3rem 5rem 12rem 5rem;
  }
  .Home .sw-wrapper .prev,
  .Home .sw-wrapper .next {
    display: none;
  }
  .Home .section-single .middle {
    height: auto;
    margin-bottom: 3rem;
  }
  .Home .section-single .center .content .text {
    max-width: 325px;
  }
  .Home .section-single .center .content {
    position: static;
    transform: none;
    width: auto;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
    max-width: 1240px;
  }
}




